<template>
  <div
    v-if="data?.contact?.[0]?.Icons?.length"
    class="contact"
  >
    <div
      v-if="data?.contact?.[0]?.Title"
      class="contact__heading heading heading--5"
    >
      {{ data.contact[0].Title }}
    </div>

    <ul class="contact__list">
      <li
        v-for="item in data.contact[0].Icons"
        :key="item"
        class="contact__item"
      >
        <component
          :is="getElement(item.Link)"
          class="contact__link"
          :to="item.Link"
        >
          <component
            :is="loadIcon(item.Icon)"
            class="contact__icon icon"
          />
          <span>
            {{ item.Name }}
          </span>
        </component>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import layoutQuery from '~layers/app/graphql/layout.gql'

import {
  AppLink,
} from '#components'

const { public: config } = useRuntimeConfig()
const { data } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale })

function getElement(to: any) {
  return to ? AppLink : 'span'
}

const { loadIcon } = useStrapiIcons()
</script>

<!-- <style src="~layers/app/components/App/Contact.css" /> -->
<style>
@import url("~layers/app/components/App/Contact.css");
</style>
