<template>
  <div class="notification">
    <div
      class="message"
      :class="[
        'message--' + (notification?.type || 'default'),
        { active: notification },
      ]"
    >
      <Transition name="fade">
        <div
          v-if="notification"
          class="notification__item"
        >
          <span class="notification__text">{{ notification.message }}</span>
          <IconClose
            class="notification__close"
            @click="close()"
          />
        </div>
      </Transition>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { notification, close } = useNotification()
</script>

<!-- <style src="~layers/app/components/App/Notification.css" /> -->
<style>
@import url("~layers/app/components/App/Notification.css");
</style>
